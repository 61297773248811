import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";

interface InstallPromptEvent extends Event {
  prompt: () => void;
  userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
}

function App() {
  const [installPrompt, setInstallPrompt] = useState<InstallPromptEvent | null>(
    null
  );

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: InstallPromptEvent) => {
      event.preventDefault(); // Prevent the mini-infobar from appearing on mobile
      setInstallPrompt(event); // Save the event for later use
    };

    window.addEventListener(
      "beforeinstallprompt",
      handleBeforeInstallPrompt as EventListener
    );

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt as EventListener
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (installPrompt) {
      installPrompt.prompt(); // Show the install prompt
      installPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setInstallPrompt(null); // After user choice, reset the prompt
      });
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        {installPrompt && (
          <button onClick={handleInstallClick}>Install App</button>
        )}
      </header>
    </div>
  );
}

export default App;
